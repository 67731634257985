import React, { useState,useEffect } from "react";
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';

const baseurl = process.env.REACT_APP_BASE_URL
const slotEndpoint = process.env.REACT_APP_SLOT_UPDATE_ENDPOINT;
const slotEndpointFetch = process.env.REACT_APP_SLOT_EDIT_ENDPOINT;
// const cityId = localStorage.getItem('cityId');

const Slot = () => {
    const [date, setDate] = useState('');
    const [page1, setPage1] = useState(false);
    const [page2, setPage2] = useState(false);
    const [page3, setPage3] = useState(false);
    const [page4, setPage4] = useState(false);
    const [status, setStatus] = useState(false);
    const [error, setError] = useState('');
    const location = useLocation();
    const selectId = location.state && location.state.slotId;

    useEffect(() => {

        const fetchData = async () => {
             const query = {_id:selectId}; 
            try {
                const response = await axios.post(`${baseurl}/${slotEndpointFetch}`,query);
                const slotData = response.data.data;

                // Populate the form fields with the retrieved data
                setDate(moment(slotData.date).format('YYYY-MM-DD'));
                setPage1(slotData.page1);
                setPage2(slotData.page2);
                setPage3(slotData.page3);
                setPage4(slotData.page4);
                setStatus(slotData.status);
            } catch (error) {
                console.error('Error fetching slot data:', error);
            }
        };

        // Call fetchData when the component mounts
        fetchData();
    }, [selectId]);

    const handleDateChange = (e) => {
        setDate(e.target.value);
        setError(''); 
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const data = {
                _id:selectId,
                date,
                page1,
                page2,
                page3,
                page4,
                status
            }
             console.log(data)
            const response = await axios.put(`${baseurl}/${slotEndpoint}`, data);
            if(response.data.statuscode == 201){
                setError(response.data.message);
            }
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Ads booking successful',
                });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <>
                        <div className="container-fluid mt-5">
                        <div className="col-2 mb-3"><h3 className="table-Title p-2">Edit slot</h3></div>
                            <form onSubmit={handleSubmit}>
                                <div className="col-6 form-group mb-4">
                                    <label htmlFor="date" className="all_label">Date:</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="date"
                                        value={date}
                                        onChange={handleDateChange} 
                                    />
                                </div>
                                {error && <div className="text-danger">{error}</div>}
                                <div className="form-group mb-4">
                                    <label className="all_label">Page 1:</label>
                                    <input
                                        type="checkbox"
                                        checked={page1}
                                        onChange={(e) => setPage1(e.target.checked)}
                                    />
                                </div>
                                <div className="form-group mb-4">
                                    <label className="all_label">Page 2:</label>
                                    <input
                                        type="checkbox"
                                        checked={page2}
                                        onChange={(e) => setPage2(e.target.checked)}
                                    />
                                </div>
                                <div className="form-group mb-4">
                                    <label className="all_label">Page 3:</label>
                                    <input
                                        type="checkbox"
                                        checked={page3}
                                        onChange={(e) => setPage3(e.target.checked)}
                                    />
                                </div>
                                <div className="form-group mb-4">
                                    <label className="all_label">Page 4:</label>
                                    <input
                                        type="checkbox"
                                        checked={page4}
                                        onChange={(e) => setPage4(e.target.checked)}
                                    />
                                </div>
                                <div className="form-group mb-4">
                                    <label className="all_label">Status:</label>
                                    <div>
                                        <label className="all_label">
                                            <input
                                                type="radio"
                                                value="true"
                                                checked={status === true}
                                                onChange={(e) => setStatus(true)}
                                            /> Active
                                        </label>
                                        <label className="all_label">
                                            <input
                                                type="radio"
                                                value="false"
                                                checked={status === false}
                                                onChange={(e) => setStatus(false)}
                                            /> Inactive
                                        </label>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary bg-primary">Submit</button>
                            </form>
                        </div>
                    
        </>
    )
}

export default Slot;
