import React, { useEffect, useState } from "react";
import { Thead,Table,Tr,Th,Td, Tbody } from "react-super-responsive-table";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import axios from "axios";
import moment from 'moment';
const baseurl = process.env.REACT_APP_BASE_URL;
const paymentEndpoint = process.env.REACT_APP_PAYMENT_LIST_ENDPOINT;
const cityId = localStorage.getItem('cityId');



const Payment = () => {
    const [data, setData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${baseurl}/${paymentEndpoint}`,{cityId:cityId});
                if(response.data.status == true){
                    setData(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const formatDate = (dateString) => {
        return moment(dateString).format('DD-MM-YYYY');
    };

    return (
        <>
                        <div className="container-fluid mt-5 table-container p-5">
                        <div className="col-sm-12 col-md-4 col-lg-3 mb-3"><h3 className="table-Title p-2">Payment</h3></div>
                            <Table style={{fontSize:"10px"}}>
                                <Thead className="table-head">
                                    <Tr>
                                        <Th>No.</Th>
                                        <Th>SLOT DATE</Th>
                                        <Th>AD_ID</Th>
                                        <Th>NAME</Th>
                                        <Th>EMAIL</Th>
                                        <Th>MOBILE</Th>
                                        <Th>LOCATION</Th>
                                        <Th>AMOUNT</Th>
                                        <Th>PAYMENT TYPE</Th>
                                        <Th>STATUS</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                {data.map((item, index) => (
                                    <Tr key={index}>
                                        <Td>{index + 1}</Td>
                                        <Td>{formatDate(item.booked_id ? item.booked_id.slot_Date : '-')}</Td>
                                        <Td>{item.booked_id ? item.booked_id.ads_id.unique_id : '-'}</Td>
                                        <Td>{item.booked_id && item.booked_id.client_id ? item.booked_id.client_id.name : '-'}</Td>
                                        <Td>{item.booked_id && item.booked_id.client_id ? item.booked_id.client_id.email : '-'}</Td>
                                        <Td>{item.booked_id && item.booked_id.client_id ? item.booked_id.client_id.mobile : '-'}</Td>
                                        <Td>{item.booked_id && item.booked_id.client_id ? item.booked_id.client_id.location : '-'}</Td>
                                        <Td>{item.totalAmount}</Td>
                                        <Td>{item.booked_id && item.booked_id.client_id ? item.booked_id.client_id.payment_type : '-'}</Td>
                                        <Td style={{ color: item.booked_id.payment_Status ? 'green' : 'red' }}>{item.booked_id.payment_Status ? 'success' : 'failed'}</Td>
                                    </Tr>
                                ))}
                                </Tbody>
                            </Table>
                        </div>
        </>
    )
}

export default Payment;
