import { React, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Logo from '../../assets/images/logoImage.png';
import Footer from 'components/footer/Footer'

const baseUrl = process.env.REACT_APP_BASE_URL;
const loginEndPoint = process.env.REACT_APP_ADMIN_LOGIN_ENDPOINT;


const Login = () => {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setpasswordError] = useState(null);
  const [serverError, setserverError] = useState(null)

  const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setEmailError('email is require!.');
      return;
    }
    if (!password) {
      setpasswordError('password is require!.');
      return;
    }
    if (!validateEmail(email)) {
      setEmailError('Invalid email address.');
      return;
    }
    if (!ValidPassword(password)) {
      setpasswordError('Password should contain 8 characters!');
      return;
    }

    await handleChange()

  }

  const validateEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }
  const ValidPassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  }
  const handleChange = async () => {
    const data = {
      email: email,
      password: password,
    // isAdmin: "true",
    };
    try {
      const result = await axios.post(`${baseUrl}/${loginEndPoint}`, JSON.stringify(data), config);
      if (result.data.status === true) {
        const isAdmin = result.data.data.isAdmin;
        console.log(result.data.data.isAdmin)
        
            if (isAdmin === true) {
              localStorage.setItem('isAdmin', result.data.data.isAdmin);
                localStorage.setItem('token', result.data.data.token);
            } else {
                localStorage.setItem('token', result.data.data.token);
                localStorage.setItem('cityId', result.data.data.cityId);
            }
        navigate("admin/dashboard");
        window.location.reload()
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setserverError('Login failed internet server error!')
    }
  };




  return (
    <>
      <div className='container sectionLogin'>
        <div className='row d-flex justify-content-center mt-5 login-card'>
          <div className="col-lg-6 col-md-12 col-sm-12 pt-3 login-card-col">
            <img className="img-fluid px-5" width="60%" loading="lazy" src={Logo} alt="Logo" />
          </div>
          <div className='col-lg-6 col-md-8 col-sm-4 login-form'>
            <form onSubmit={handleSubmit}>
              <div className="row d-flex justify-content-center p-5  ">
          <h3 className='login-head text-center mb-5'>Login</h3>

                <div className="form-group row mt-3">
                  <div className="col-sm-12">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input type="email" className="form-control form-control_login" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="name@example.com" required />
                    {emailError && <div className="text-end text-danger">{emailError}</div>}
                  </div>
                </div>
                <div className="form-group row mt-3">
                  <div className="col-sm-12">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input type="password" className="form-control form-control_login" name="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    {passwordError && <div className="text-end text-danger">{passwordError}</div>}
                  </div>
                </div>
                <div className="form-group mt-3 d-flex justify-content-center">
                  <button className="btn login-btn mt-5" type="submit" onClick={handleSubmit} >Login</button>
                </div>
                {serverError && <div className="text-end text-danger">{serverError}</div>}

              </div>
            </form>
          </div>
        </div>

        <div className='row footer'>
          <Footer />
        </div>
      </div>

      {/* <section className="pt-5 p-md-4 p-xl-5 sectionLogin">
      <div className="container login">
        <div className="row d-flex justify-content-center g-0 login-card">
          <div className="col-lg-6 col-md-12 col-sm-12 pt-3 login-card-col">
            <img className="img-fluid px-5" width="60%" loading="lazy" src={Logo} alt="Logo" />
          </div>
          <div className="col-lg-6 col-md-5 col-sm-4">
            <div className="card-body login-form">
              <div className="row text-center">
                <div className="col-12">
                  <div className="">
                    <h3 className='login-head'>Login</h3>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row d-flex justify-content-center p-5">
                  <div className="form-group row mt-3">
                    <div className="col-sm-12">
                      <label htmlFor="email" className="form-label">Email</label>
                      <input type="email" className="form-control form-control_login" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="name@example.com" required />
                      {emailError && <div className="text-end text-danger">{emailError}</div>}
                    </div>
                  </div>
                  <div className="form-group row mt-3">
                    <div className="col-sm-12">
                      <label htmlFor="password" className="form-label">Password</label>
                      <input type="password" className="form-control form-control_login" name="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                      {passwordError && <div className="text-end text-danger">{passwordError}</div>}
                    </div>
                  </div>
                  <div className="form-group mt-3 d-flex justify-content-center">
                    <button className="btn login-btn mt-5" type="submit" onClick={handleSubmit} >Login</button>
                  </div>
                  {serverError && <div className="text-end text-danger">{serverError}</div>}

                </div>
              </form>
            </div>
          </div>
        </div>

      </div>


    </section> */}

    </>
  )
};

export default Login;


