import Card from "components/card";
import { useNavigate } from 'react-router-dom';

const Widget = ({ icon, title, subtitle }) => {
  
  const navigate = useNavigate();

  const handleWidgetClick = (e) => {
    navigate('/admin/BookedAds');
  };
  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px] hover:text-white p-3 widget" onClick={handleWidgetClick} >
      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <h1 className="font-dm text-xl">{title}</h1>
        <h4 className="text-xl font-bold subtitle">
          {subtitle}
        </h4>
      </div>
    </Card>
  );
};

export default Widget;
