import React, { useEffect, useState } from "react";
import { Thead, Table, Tr, Th, Td, Tbody } from "react-super-responsive-table";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import axios from "axios";
import moment from 'moment';

const baseurl = process.env.REACT_APP_BASE_URL;
const clientsEndpoint = process.env.REACT_APP_BOOKED_AD_CLIENT_ENDPOINT;
const deleteClientEndpoint = process.env.REACT_APP_DELETE_CLIENT_ENDPOINT;
const updateClientEdpoint = process.env.REACT_APP_UPDATE_CLIENT_ENDPOINT;
const cityId = localStorage.getItem('cityId');

const Clients = () => {
    const [data, setData] = useState([]);
    const [editingClient, setEditingClient] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${baseurl}/${clientsEndpoint}`;
                const response = await axios.post(url, { cityId: cityId });
                // console.log(response.data.data[0].payment_Status)

                setData(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleDelete = async (clientId) => {
        try {
            const response = await axios.delete(`${baseurl}/${deleteClientEndpoint}`, { data: { _id: clientId } });
            if (response.data.status) {
                setData(data.filter(client => client._id !== clientId));
            } else {
                console.error('Delete client failed:', response.data.message);
            }
        } catch (error) {
            console.error('Error deleting client:', error);
        }
    };

    const handleEdit = (clientId) => {
        const clientToEdit = data.find(client => client._id === clientId);
        setEditingClient(clientToEdit);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEditingClient(null);
    };

    const handleUpdate = async (updatedClient) => {
        try {
            const response = await axios.put(`${baseurl}/${updateClientEdpoint}`, updatedClient);
            if (response.data.status) {
                setData(data.map(client => client._id === updatedClient._id ? updatedClient : client));
                setShowModal(false);
                setEditingClient(null);
            } else {
                console.error('Update client failed:', response.data.message);
            }
        } catch (error) {
            console.error('Error updating client:', error);
        }
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('DD-MM-YYYY');
    }
    return (
        <>

            <div className="container-fluid mt-5 table-container p-5">
                <div className="col-sm-12 col-lg-2 mb-3"><h3 className="table-Title p-2">Clients</h3></div>
                <Table>
                    <Thead className="table-head">
                        <Tr>
                            <Th>No.</Th>
                            <Th>NAME</Th>
                            <Th>EMAIL</Th>
                            <Th>MOBILE</Th>
                            <Th>LOCATION</Th>
                            <Th>ADS_ID</Th>
                            <Th>PAGE SIZE</Th>
                            <Th>SLOT_DATE</Th>
                            <Th>PAYMENT STATUS</Th>
                            {/* <Th>ACTION</Th> */}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.map((item, index) => (
                            <Tr key={index}>
                                <Td>{index + 1}</Td>
                                <Td>{item.client_id.name}</Td>
                                <Td>{item.client_id.email}</Td>
                                <Td>{item.client_id.mobile}</Td>
                                <Td>{item.client_id.location}</Td>
                                <Td>{item.ads_id.unique_id}</Td>
                                <Td>{item.ads_id.pageSize}</Td>
                                <Td>{formatDate(item.slot_Date)}</Td>
                                <Td style={{ color: `${item.payment_Status ? 'green' : 'red'}` }}>{item.payment_Status ? 'Success' : 'Failed'}</Td>
                                {/* <Td>{item.payment_Status.toString()}</Td> */}

                                {/* <Td>
                                                    <button className="btn btn-primary m-1" onClick={() => handleEdit(item._id)}>Edit</button>
                                                    <button className="btn btn-danger" onClick={() => handleDelete(item._id)}>Delete</button>
                                                </Td>
                                                */}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>

                {/* 
                {editingClient && (
                <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Edit Client</h4>
                                <button type="button" className="close" onClick={handleCloseModal}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    handleUpdate(editingClient);
                                }}>
                                    <div className="form-group">
                                        <label htmlFor="name">Name:</label>
                                        <input type="text" className="form-control" id="name" value={editingClient.name} onChange={(e) => setEditingClient({ ...editingClient, name: e.target.value })} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email:</label>
                                        <input type="email" className="form-control" id="email" value={editingClient.email} onChange={(e) => setEditingClient({ ...editingClient, email: e.target.value })} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="mobile">Mobile:</label>
                                        <input type="text" className="form-control" id="mobile" value={editingClient.mobile} onChange={(e) => setEditingClient({ ...editingClient, mobile: e.target.value })} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <label htmlFor="location">Location:</label>
                                        <input type="text" className="form-control" id="location" value={editingClient.location} onChange={(e) => setEditingClient({ ...editingClient, location: e.target.value })} />
                                    </div>
                                    <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary m-2">Save Changes</button>
                                    <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )} */}
            </div>
        </>
    )
}

export default Clients;
