import Chart from "react-apexcharts";
import React, { useEffect, useState } from 'react';
import ProgressBar from "@ramonak/react-progress-bar";



const ProcessBar = ({completed}) => {
  return <ProgressBar
  completed={completed}
    className="wrapper"
    barContainerClassName="fullBar"
    labelClassName="label" />;
};
export default ProcessBar;
