import React, { useEffect, useState } from "react";
import { Thead,Table,Tr,Th,Td, Tbody } from "react-super-responsive-table";
import axios from "axios";
import moment from 'moment';
const baseurl = process.env.REACT_APP_BASE_URL;
const successEndpoint = process.env.REACT_APP_PRE_BOOKING_LIST_ENDPOINT;
const cityId = localStorage.getItem('cityId');

const Success = () => {
    const [data, setData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${baseurl}/${successEndpoint}`,{cityId:cityId});
                setData(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const formatDate = (dateString) => {
        return moment(dateString).format('DD-MM-YYYY');
    };

    return (
        <>
                        <div className="container-fluid mt-5 table-container p-5">
                        <div className="col-sm-6 col-lg-4 mb-3"><h3 className="table-Title p-2">success Story Booking</h3></div>
                            <Table>
                                <Thead className="table-head">
                                    <Tr>
                                        <Th>No.</Th>
                                        <Th>NAME</Th>
                                        <Th>EMAIL</Th>
                                        <Th>MOBILE</Th>
                                        <Th>LOCATION</Th>
                                        <Th>AMOUNT</Th>
                                        <Th>PAYMENT TYPE</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                {data.map((item, index) => (
                                    <Tr key={index}>
                                        <Td>{index + 1}</Td>
                                        <Td>{item.name}</Td>
                                        <Td>{item.email}</Td>
                                        <Td>{item.mobile}</Td>
                                        <Td>{item.location}</Td>
                                        <Td>{item.totalAmount}</Td>
                                        <Td style={{ color: item.payment_Status ? 'green' : 'red' }}>{item.payment_Status ? 'success' : 'failed'}</Td>
                                    </Tr>
                                ))}
                                </Tbody>
                            </Table>
                        </div>
        </>
    )
}

export default Success;
