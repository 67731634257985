import Card from "components/card";

const Widget = ({ icon, title, subtitle }) => {
  
  
  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px] widget2">
      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <h1 className="font-dm mb-3 widget2-title">{title}</h1>
        <h4 className="text-center widget2-subtitle">
          {subtitle}
        </h4>
      </div>
    </Card>
  );
};

export default Widget;
