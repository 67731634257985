import React, { useEffect, useState } from "react";
import { Thead,Table,Tr,Th,Td, Tbody } from "react-super-responsive-table";
import axios from "axios";
import moment from 'moment';
import { Modal } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
import LoadingImage from 'assets/images/loading.png'

const url = "https://qgbimages.s3.ap-south-1.amazonaws.com/uploadnewsPaper"
const baseurl = process.env.REACT_APP_BASE_URL
const slotEndpoint = process.env.REACT_APP_SLOT_LIST_ENDPOINT;
const uploadEndpoint = process.env.REACT_APP_UPLOAD_PAPER_IMAGE_ENDPOINT

const Slotlist = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [slots, setSlots] = useState([]);
    const [open_add_slotModal, setopen_add_slotModal] = useState(false);
    const [selectedSlotId, setSelectedSlotId] = useState(null);
    const [error, setError] = useState('');
    const [uploadpaper, setUploadPaper] = useState(null);
    const cityId = localStorage.getItem('cityId');


    const openModal = () => {
        setopen_add_slotModal(true);
    };

    const closeModal = () => {
        setopen_add_slotModal(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${baseurl}/${slotEndpoint}`,{cityId:cityId});
                setSlots(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    const handleUploadChange = (e) => {
        setUploadPaper(e.target.files[0]);
        setError('');
    };

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('uploadpaper', uploadpaper);
            formData.append('_id', selectedSlotId);
            const response = await axios.post(`${baseurl}/${uploadEndpoint}`, formData, config);

            if (response.data.statusCode == 201) {
                setError(response.data.message);
            }
            if (response.status === 200) {
                closeModal();
                window.location.reload();
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('DD-MM-YYYY');
    };

    const handleOpen = (slotId) => {
        setSelectedSlotId(slotId);
        openModal();
    };

    return (
        <>
            <div className="container-fluid mt-5 table-container p-5">
            <div className="col-sm-12 col-md-6 col-lg-4 mb-3"><h3 className="table-Title p-2">upload paper</h3></div>
                <Table>
                    <Thead className="table-head">
                        <Tr>
                            <Th>No.</Th>
                            <Th>DATE</Th>
                            <Th>ACTION</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {slots.map((slot, index) => (
                            <Tr key={index}>
                                <Td>{index + 1}</Td>
                                <Td>{formatDate(slot.date)}</Td>
                                <Td>
                                    {slot.uploadpaper ? (
                                        <a href={`${url}/${slot.uploadpaper}`} target="_blank" className="btn btn-primary">View</a>
                                    ) :
                                        (<button className="btn btn-primary" onClick={() => handleOpen(slot._id)}>Upload</button>)}
                                </Td>
                                {/* <Td>
                                                <button className="btn btn-primary" onClick={() => handleOpen(formatDate(slot._id))}>Upload</button>
                                                {slot.uploadpaper && <a href={`${url}/${slot.uploadpaper}`} target="_blank" className="btn btn-primary">View</a>}
                                            </Td> */}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>

                <Modal show={open_add_slotModal} onHide={closeModal} dialogClassName="modal-responsive">
                    <Modal.Header closeButton>
                        <Modal.Title>Add Slot</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>upload file:</label>
                                <input
                                    type="file"
                                    className="form"
                                    onChange={handleUploadChange}
                                />
                            </div>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <button type="submit" className="btn btn-primary bg-primary" onClick={handleSubmit}>Submit</button>
                        </form>
                    </Modal.Body>
                </Modal>
                {isLoading && (
                    <div className="text-center">
                        <img src={LoadingImage} alt="Loading..." className="loading  " />
                    </div>
                )}
            </div>
        </>

    )
}

export default Slotlist;
