import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Ads1 from 'assets/images/ads1.jpg';
import Ads2 from 'assets/images/ads2.jpg';
import Ads3 from 'assets/images/ads3.jpg';
import Ads4 from 'assets/images/ads4.jpg';

import Fullsize from 'assets/images/full jacket ads.jpg';
import Half from 'assets/images/half jacket ads.jpg';
import Visibility from 'assets/images/visibility ads.jpg';
import Pocket from 'assets/images/pocket ads.jpg';

import axios from 'axios';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const pageEndpoint = process.env.REACT_APP_CHECK_PAGE_AVAIBILITY_ENDPOINT;
const baseurl = process.env.REACT_APP_BASE_URL;
const cityId = localStorage.getItem('cityId');

const Selectpage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedDate = location.state && location.state.day;
    const slotId = location.state && location.state.slotId;
    const [slotData, setSlotData] = useState(null);

    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);


    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    const navigate_To_page1 = () => {
        navigate('/admin/Page1', { state: { selectedDate, slotId } });
    };
    const navigate_To_page2 = () => {
        navigate("/admin/Page2", { state: { selectedDate, slotId } });
    };
    const navigate_To_page3 = () => {
        navigate("/admin/Page3", { state: { selectedDate, slotId } });
    };
    const navigate_To_page4 = () => {
        navigate("/admin/Page4", { state: { selectedDate, slotId } });
    };

    const formattedDate = selectedDate ? moment(selectedDate).format('DD-MM-YYYY') : null;

    const checkPageAvailability = async (formattedDate) => {
        try {
            const response = await axios.post(`${baseurl}/${pageEndpoint}`, { date: formattedDate,cityId:cityId });
            if (response.data.status) {
                setSlotData(response.data.data);
            } else {
                console.log("Page is not available");
            }
        } catch (error) {
            console.error('Error checking page availability:', error);
        }
    };
    useEffect(() => {
        if(!formattedDate){
            navigate('/admin/BookedAds')
        }
        if (formattedDate) {
            checkPageAvailability(formattedDate);
        }
    }, [formattedDate]);
    
    return (

        <>
            <div className="container-fluid slot mt-5">
                <div className="row d-flex justify-content-center">
                    <div className="row d-flex justify-content-center slot-dates">
                        <h2 className="text-center headings mt-4 mb-4">Selected Date: {formattedDate}</h2>
                        {/* -------------page1--------- */}
                        {slotData && slotData.page1 == true && (
                            <div className="col-lg-2 col-md-6 col-sm-6 col-6" id="page1" onClick={handleShow1}>
                                <p className="text-center slot-page-head">page1</p>
                                <img src={Ads1} width="90%" height='auto' alt="Ads1" />
                            </div>
                        )}
                        {/* -------------page2--------- */}
                        {slotData && slotData.page2 == true && (
                            <div className="col-lg-2 col-md-6 col-sm-6 col-6  " id="page2" onClick={handleShow2}>
                                <p className="text-center slot-page-head">page2</p>
                                <img src={Ads2} width="90%" height='auto' alt="Ads2" />
                            </div>
                        )}
                        {/* -------------page3--------- */}
                        {slotData && slotData.page3 == true && (
                            <div className="col-lg-2 col-md-6 col-sm-6 col-6   " id="page3" onClick={handleShow3}>
                                <p className="text-center slot-page-head">page3</p>
                                <img src={Ads3} width="90%" height='auto' alt="Ads3" />
                            </div>

                        )}
                        {/* -------------page4--------- */}
                        {slotData && slotData.page4 == true && (
                            <div className="col-lg-2 col-md-6 col-sm-6 col-6   " id="page4" onClick={handleShow4}>
                                <p className="text-center slot-page-head">page4</p>
                                <img src={Ads4} width="90%" height='auto' alt="Ads4" />
                            </div>
                        )}
                    </div>
                </div>

            </div>

            <Modal show={show1} onHide={handleClose1} className="slot-modal" centered>
                <Modal.Header closeButton>
                <Modal.Title className="headings">Page 1</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="text-center">
                        <img src={Fullsize} width="50%" height='auto' alt="full" />
                    </div>

                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button className="btn btn-primary bg-primary" onClick={navigate_To_page1}>Select</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show2} onHide={handleClose2} className="slot-modal" centered>
                <Modal.Header closeButton>
                <Modal.Title className="headings text-center">Page 2</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <img src={Visibility} width="50%" height='auto' alt="visibilty" />
                    </div>

                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button className="btn btn-primary bg-primary" onClick={navigate_To_page2}>Select</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show3} onHide={handleClose3} className="slot-modal" centered>
                <Modal.Header closeButton>
                <Modal.Title className="headings text-center">Page 3</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <img src={Pocket} width="50%" height='auto' alt="half" />
                    </div>

                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button className="btn btn-primary bg-primary" onClick={navigate_To_page3}>Select</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show4} onHide={handleClose4} className="slot-modal" centered>
                <Modal.Header closeButton>
                <Modal.Title className="headings text-center">Page 4</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <img src={Half} width="50%" height='auto' alt="pocket" />
                    </div>

                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button className="btn btn-primary bg-primary" onClick={navigate_To_page4}>Select</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Selectpage;
