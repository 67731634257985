import { MdDashboard, MdPerson } from "react-icons/md";
import { useState, useEffect } from 'react';
import { IoIosPeople } from "react-icons/io";
import { AiFillGold } from "react-icons/ai";
import Widget from "components/widget/Widget";
import WidgetDate from "components/widget/WidgetDate";
import Widget2 from "components/widget/Widget2";
import ProcessBar from 'components/charts/processBar'
import axios from "axios";
import moment from 'moment';
import Swal from 'sweetalert2';
import Dropdown from 'react-bootstrap/Dropdown';

const baseurl = process.env.REACT_APP_BASE_URL;
const bookedEndpoint = process.env.REACT_APP_FIND_BOOKED_ADS_ENDPOINT;
const pendingEndpoint = process.env.REACT_APP_FIND_PENDING_ADS_ENDPOINT;
const percentageEdpoint = process.env.REACT_APP_SLOT_PERCENTAGE_ENDPOINT;
const paymentEndpoint = process.env.REACT_APP_PAYMENT_ENDPOINT;
const taxEndpoint = process.env.REACT_APP_TAX_ENDPOINT;
const revenueEndpoint = process.env.REACT_APP_REVENUE_ENDPOINT
const slotEndpoint = process.env.REACT_APP_SLOT_LIST_ENDPOINT

const Dashboard = () => {
    const [data, setData] = useState([]);
    const [peddingAds, setpeddingAds] = useState(0);
    const [percentage, setpercentage] = useState(0)
    const [payment, setPayment] = useState(0);
    const [tax, setTax] = useState(0);
    const [revenue, setRevenue] = useState(0)
    const [slots, setSlots] = useState([]);
    // const [selectedCity, setSelectedCity] = useState(1);

    const localStorageID = localStorage.getItem('cityId');
    const IsAdmin = localStorage.getItem('isAdmin');
    let initialCityId ;

    if (IsAdmin === true) {
        initialCityId = 1  
    }else{
        initialCityId = localStorageID
    }
    const [selectedCity, setSelectedCity] = useState(initialCityId);
      
  
    useEffect(() => {
        fetchData(selectedCity);
        localStorage.setItem('cityId', selectedCity);
    }, [selectedCity]);

    const fetchData = async (cityId) => {
        try {
            cityId = parseInt(cityId);
            const response = await axios.post(`${baseurl}/${slotEndpoint}`, { cityId:cityId });
            setSlots(response.data.data);
            const slots = response.data.data;

            const newData = {};
            const newPendingAds = {};
            const newPercentage = {};
            const newPayment = {};
            const newTax = {};
            const newRevenue = {};

            for (const slot of slots) {
                const slotId = slot._id;

                const bookedAdsResponse = await axios.post(`${baseurl}/${bookedEndpoint}`, { slot_id: slotId,cityId:cityId});
                const pendingAdsResponse = await axios.post(`${baseurl}/${pendingEndpoint}`, { slot_id: slotId,cityId:cityId });
                const paymentResponse = await axios.post(`${baseurl}/${paymentEndpoint}`, { slot_id: slotId,cityId:cityId});
                const taxResponse = await axios.post(`${baseurl}/${taxEndpoint}`, { slot_id: slotId ,cityId:cityId});
                const revenueResponse = await axios.post(`${baseurl}/${revenueEndpoint}`, { slot_id: slotId,cityId:cityId });
                const percentageResponse = await axios.post(`${baseurl}/${percentageEdpoint}`, { slot_id: slotId, cityId: cityId });

                newData[slotId] = bookedAdsResponse.data.data;
                newPendingAds[slotId] = pendingAdsResponse.data.data;
                newPayment[slotId] = paymentResponse.data.totalPayment;
                newTax[slotId] = taxResponse.data.taxAmount;
                newRevenue[slotId] = revenueResponse.data.Amount;
                newPercentage[slotId] = percentageResponse.data.data;
            }

            setData(newData);
            setpeddingAds(newPendingAds);
            setPayment(newPayment);
            setTax(newTax);
            setRevenue(newRevenue);
            setpercentage(newPercentage);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    const formatDate = (dateString) => {
        return moment(dateString).format('DD-MM-YYYY');
    }
    const handleCityChange = (cityId) => {
        setSelectedCity(cityId);
        window.location.reload()

    };

    return (
        <div className="container">

            <div className="row d-flex justify-content-center">
                <div className="mt-5">
                {IsAdmin && (
                    <div className="mt-5">
                        <Dropdown>
                            <Dropdown.Toggle variant="warning" id="dropdown-basic">
                                Select City
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleCityChange(2)}>Gandhinagar</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleCityChange(1)}>Ahmedabad</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )}
                    {/* <Dropdown>
                        <Dropdown.Toggle variant="warning" id="dropdown-basic">
                            select City
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setSelectedCity(2)}>Gandhinagar</Dropdown.Item>
                            <Dropdown.Item onClick={() => setSelectedCity(1)}>Ahmedabad</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}
                </div>
                {slots.map((slot, index) => (
                    slot.status ? (
                        <div>

                            <div className="mt-10 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-4 slotList-Dashboard">
                                {/* <div className="col-span-1 md:col-span-1 lg:col-span-full xl:col-span-full 2xl:col-span-full 3xl:col-span-full">
                                    <div className="col-sm-3 col-md-7 col-lg-3  slotToggle">
                                        <span className="slot-label">Slot:{index + 1} </span>
                                        <input type="checkbox" id="toggle"  />
                                        <label className="slot-toggle" htmlFor="toggle"></label>
                                    </div>
                                </div> */}
                                <WidgetDate date={formatDate(slot.date)} />

                                <Widget
                                    icon={<MdPerson className="h-7 w-7" />}
                                    title={"Booked Ads"}
                                    subtitle={data[slot._id] ? data[slot._id].length : 0}
                                />
                                <Widget
                                    icon={<MdPerson className="h-7 w-7" />}
                                    title={"Pending Ads"}
                                    subtitle={peddingAds[slot._id] ? peddingAds[slot._id] : 0}
                                />
                                <Widget
                                    icon={<MdPerson className="h-7 w-7" />}
                                    title={"Payment"}
                                    subtitle={payment[slot._id] ? payment[slot._id] : 0}
                                />
                                <div className="col-span-1 md:col-span-full lg:col-span-full xl:col-span-full 2xl:col-span-full 3xl:col-span-full d-flex justify-content-center align-items-center">
                                    <div className="col-lg-6 col-sm-12">
                                        <ProcessBar completed={percentage[slot._id] ? percentage[slot._id] : 0} />
                                    </div>
                                </div>
                                <div className="col-span-1 md:col-span-full lg:col-span-full xl:col-span-full 2xl:col-span-full 3xl:col-span-full d-flex justify-content-center align-items-center">
                                    <div className="col-sm-12 col-md-4">
                                        <Widget2
                                            icon={<MdPerson className="h-7 w-7" />}
                                            title={"Revenue:"}
                                            subtitle={revenue[slot._id] ? revenue[slot._id] : 0}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                        <Widget2
                                            icon={<MdPerson className="h-7 w-7" />}
                                            title={"Tax:"}
                                            subtitle={tax[slot._id] ? tax[slot._id] : 0}
                                        />
                                    </div>


                                </div>
                                {/* <WidgetDate /> */}


                            </div>
                        </div>
                    ) : null
                ))}
            </div>


        </div>
    );
};

export default Dashboard;
