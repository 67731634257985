import React, { useState, useEffect } from "react";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate,useLocation} from "react-router-dom";
import { MdLogout } from "react-icons/md";

const Navbar = (props) => {
const navigate = useNavigate()
const location = useLocation()
  const { onOpenSidenav, brandText } = props;
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('isAdmin');
    navigate("/")
};


  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-center">
    <div className="col-8 navbar">
      <p className="shrink text-[33px] capitalize text-white p-2">
        <span className="mx-1 font-bold capitalize  dark:text-white">
          {" "}
          /{" "}
        </span>
        <Link
          to="#"
          className="font-bold capitalize"
        >
         {/* {location.pathname} */}
          {brandText}
        </Link>
      </p>
    </div>

    <div className="col-4">
    <FiAlignJustify className="flex cursor-pointer text-xl sidebar-icon mx-1 xl:hidden "  onClick={onOpenSidenav} />
   
        <MdLogout className="logout-icon" onClick={handleLogout}/>
     
    </div>
  </nav>
    
  );
};

export default Navbar;
