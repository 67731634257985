import React from "react";
import MainDashboard from "views/admin/default";
import Inquiry from "views/admin/table/inquiry";
import Clients from "views/admin/table/client";
import Upload from "views/admin/table/uploadPaper";
import PastSlot from "views/admin/table/pastSlot";
import Payment from "views/admin/table/payment";
import Currentslot from "views/admin/table/currentSlot";
import EditSlot from "views/admin/table/editSlot";
import SelectPage from "views/admin/bookerdAds/selectPage";
import SuccessStory from 'views/admin/table/successStory';
import ProfileStory from 'views/admin/table/profileStory';
import AddProfilePage from 'views/admin/table/addProfilePage';
import EditProfilePage from 'views/admin/table/editProfilePage';
import SignIn from "views/auth/SignIn";
import Page1 from "views/admin/pages/page1";
import Page2 from "views/admin/pages/page2";
import Page3 from "views/admin/pages/page3";
import Page4 from "views/admin/pages/page4";

import BookedAds from "views/admin/bookerdAds/bookedAds";
import { BsFillPeopleFill } from "react-icons/bs";
import { IoDocumentTextSharp } from "react-icons/io5";
import {
  MdHome,
  MdDashboard,
  MdLibraryBooks,
  MdFeaturedPlayList,
  MdAppRegistration
} from "react-icons/md";
import { FaSearch,FaFileUpload,FaShareSquare,FaCreditCard } from "react-icons/fa";

const routes = [
  {
    layout: "/",
    icon: <MdHome className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdDashboard className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Inquiry",
    layout: "/admin",
    path: "Inquiry",
    icon: <FaSearch className="h-6 w-6" />,
    component: <Inquiry />,
  },
  {
    name: "Booked Ads",
    layout: "/admin",
    path: "BookedAds",
    icon: <MdLibraryBooks className="h-6 w-6" />,
    component: <BookedAds />,
  },
  {
    name: "Clients",
    layout: "/admin",
    path: "Clients",
    icon: <BsFillPeopleFill className="h-6 w-6" />,
    component: <Clients />,
  },
  {
    name: "Current Slots",
    layout: "/admin",
    path: "Currentslot",
    icon: <MdFeaturedPlayList className="h-6 w-6" />,
    component: <Currentslot />,
  },
  {
    name: "Past Slots",
    layout: "/admin",
    path: "PastSlot",
    icon: <IoDocumentTextSharp className="h-6 w-6" />,
    component: <PastSlot />,
  },
  {
    name: "Payments",
    layout: "/admin",
    path: "Payment",
    icon: <FaCreditCard className="h-6 w-6" />,
    component: <Payment />,
  },
  {
    name: "Upload Paper",
    layout: "/admin",
    path: "Upload",
    icon: <FaShareSquare className="h-6 w-6" />,
    component: <Upload />,
  },
  {
    name: "Success Story",
    layout: "/admin",
    path: "SuccessStory",
    icon: <MdAppRegistration className="h-6 w-6" />,
    component: <SuccessStory />,
  },
  {
    name: "Profile Story",
    layout: "/admin",
    path: "profileStory",
    icon: <MdAppRegistration className="h-6 w-6" />,
    component: <ProfileStory />,
  },
  {
    name: "AddProfile",
    layout: "/admin",
    path: "AddProfile",
    icon: <MdAppRegistration className="h-6 w-6" />,
    component: <AddProfilePage />,
  },
  {
    name: "EditProfile",
    layout: "/admin",
    path: "EditProfile/:id", // Dynamic path with ID parameter
    icon: <MdAppRegistration className="h-6 w-6" />,
    component: <EditProfilePage />, // Adjust component as needed
  },
  {
    name: "EditSlot",
    layout: "/admin",
    path: "EditSlot",
    icon: <FaFileUpload className="h-6 w-6" />,
    component: <EditSlot />,
  },
  {
    name: "SelectPage",
    layout: "/admin",
    path: "SelectPage",
    icon: <FaFileUpload className="h-6 w-6" />,
    component: <SelectPage />,
  },
  {
    name: "page1",
    layout: "/admin",
    path: "page1",
    icon: <FaFileUpload className="h-6 w-6" />,
    component: <Page1 />,
  },
  {
    name: "page2",
    layout: "/admin",
    path: "page2",
    icon: <FaFileUpload className="h-6 w-6" />,
    component: <Page2 />,
  },
  {
    name: "page3",
    layout: "/admin",
    path: "page3",
    icon: <FaFileUpload className="h-6 w-6" />,
    component: <Page3 />,
  },
  {
    name: "page4",
    layout: "/admin",
    path: "page4",
    icon: <FaFileUpload className="h-6 w-6" />,
    component: <Page4 />,
  }
 
];

export default routes;
