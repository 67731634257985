import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import header from 'assets/images/logoImage.png';
import gnvHeader from 'assets/images/gandhinagarLogo.png'
import Scan from 'assets/images/scan.jpg';
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const moment = require('moment');

const baseurl = process.env.REACT_APP_BASE_URL;
const adsEndpoint = process.env.REACT_APP_FIND_ADS_ENDPOINT;
const bookedAdsEndpoint = process.env.REACT_APP_BOOKED_ADS_ENDPOINT;
const clientEndpoint = process.env.REACT_APP_CREATECLIENT_ENDPOINT;
const paymentEndpoint = process.env.REACT_APP_ADS_PAYMENT_ENDPOINT;
const payment_key = process.env.REACT_APP_KEY_ID;
const createPayment_Endpoint = process.env.REACT_APP_CREATE_PAYMENT_ENDPOINT;
const paymentStatus_Endpoint = process.env.REACT_APP_UPDATE_PAYMENT_STATUS_ENDPOINT;
const uploadimageEndpoint = process.env.REACT_APP_BOOKED_ADS_UPLOAD_IMG_ENDPOINT
const emailEndpoint = process.env.REACT_APP_ADS_EMAIL_ENDPOINT;
const imageurl = "https://qgbimages.s3.ap-south-1.amazonaws.com/AdsImage"
const cityId = localStorage.getItem('cityId');

const Page2 = () => {
    const navigate = useNavigate()
    const Location = useLocation();
    const [orderId, setOrderId] = useState('');
    const selectedDate = Location.state && Location.state.selectedDate;
    const slotId = Location.state && Location.state.slotId;
    const formattedDate = selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null;
    const formattedDateForPage = selectedDate ? moment(selectedDate).format('DD-MM-YYYY') : null;

    if (formattedDate == null) {
        navigate('/admin/BookedAds')
    }
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    const [open_add_slotModal, setopen_add_slotModal] = useState(false);
    const [selectedSlotId, setSelectedSlotId] = useState(null);
    const [selectedImage, setselectedImage] = useState(null);
    const [error, setError] = useState('');
    const [show, setShow] = useState(false);
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [GST_No, setGST_No] = useState('')
    const [paymentMode, setPaymentMode] = useState('offline');
    const [location, setLocation] = useState('')

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [locationError, setLocationError] = useState('');



    const [data, setData] = useState([]);
    const [selectedAdId, setSelectedAdId] = useState(null);
    const [clientName, setClientName] = useState('');



    const handleUploadChange = (e) => {

        setselectedImage(e.target.files[0]);
        setError('');
    };

    const handleUploadImage = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('image', selectedImage);
            formData.append('_id', selectedSlotId);
            console.log(selectedSlotId)
            const response = await axios.put(`${baseurl}/${uploadimageEndpoint}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.status == true) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'image upload successful',
                    // showCancelButton: true,
                    confirmButtonText: 'ok',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            }
        } catch (error) {
            console.error('Error uploading image:', error);

        }
    };

    const handleKeyDown = (e) => {
        if (!((e.keyCode > 95 && e.keyCode < 106) || // Numpad keys
            (e.keyCode > 47 && e.keyCode < 58) ||  // Number keys
            e.keyCode === 8 || e.keyCode === 9 ||  // Backspace and Tab
            e.keyCode === 37 || e.keyCode === 39 || // Left and Right arrow keys
            e.keyCode === 46                         // Delete key
        )) {
            e.preventDefault();
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            try {

                const data = {
                    page_id: "65eeda24e1c47776ec8ce284",
                    slot_Date: formattedDate,

                }
                const url = `${baseurl}/${adsEndpoint}`;
                const response = await axios.post(url, data)
                setData(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handlePayment = async (TotalAmount, booked_id) => {
        const amountNumber = parseFloat(TotalAmount);
        try {
            const createPaymentResponse = await axios.post(`${baseurl}/${createPayment_Endpoint}`, {
                orderId: orderId,
                slot_id: slotId,
                booked_id: booked_id,
                amount: amountNumber,
                cityId: cityId
            });
            if (createPaymentResponse.data.status == true) {
                console.log("payment successfull")
            }
            const updatePaymentResponse = await axios.put(`${baseurl}/${paymentStatus_Endpoint}`, {
                orderId: orderId,
                booked_id: booked_id
            });
            if (updatePaymentResponse.data.status == true) {
                console.log('status update successfull')
            }
            const sendEmail = await axios.post(`${baseurl}/${emailEndpoint}`, { booked_id: booked_id, cityId: cityId });
            if (sendEmail.data.status == true) {
                navigate('/admin/Payment', { state: { orderId: sendEmail.data.data.orderId } });
            }
        } catch (error) {
            console.error('Error updating payment status:', error);
        };
    }

    const handleBookAds = async (adsId) => {

        try {
            setSelectedAdId(adsId);
            setShow(true);
        } catch (error) {
            console.error('Error booking ads:', error);
        }
    };
    const handleClose = () => setShow(false);
    const closeModal = () => {
        setopen_add_slotModal(false);
    };
    const openModal = (booked_id) => {
        setSelectedSlotId(booked_id)
        setopen_add_slotModal(true);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name) {
            setNameError('Name is required');
            return;
        } else {
            setNameError('');
        }


        // Validation for email
        const emailPattern = /\S+@\S+\.\S+/;
        if (!email) {
            setEmailError('Email is required');
            return;
        } else if (!emailPattern.test(email)) {
            setEmailError('Invalid email format');
            return;
        } else {
            setEmailError('');
        }

        // Validation for mobile
        const mobilePattern = /^\d{10}$/;
        if (!mobile) {
            setMobileError('Mobile is required');
            return;
        } else if (!mobilePattern.test(mobile)) {
            setMobileError('Invalid mobile number format');
            return;
        } else {
            setMobileError('');
        }

        if (!location) {
            setLocationError('Location is required');
            return;
        } else {
            setLocationError('');
        }
        try {
            const clientData = {
                name: name,
                email: email,
                mobile: mobile,
                location: location,
                GST_No: GST_No,
                payment_type: 'offline',
                cityId: cityId
            }
            const clientresponse = await axios.post(`${baseurl}/${clientEndpoint}`, clientData);
            const clientId = clientresponse.data.data._id
            const data = {
                ads_id: selectedAdId,
                page_id: "65eeda24e1c47776ec8ce284",
                slot_id: slotId,
                client_id: clientId,
                slot_Date: formattedDate,
                cityId: cityId
            }
            const response = await axios.post(`${baseurl}/${bookedAdsEndpoint}`, data);
            if (response.status === 200) {
                const amount = response.data.data.ads_id.price
                const booked_id = response.data.data._id
                setShow(false);
                if (paymentMode == "offline") {
                    handlePayment(amount, booked_id)
                } else {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Ads booking successful',
                    });
                }
            } else {
                console.error('Error booking ads:', response.data.message);
            }
        } catch (error) {
            console.error('Error booking ads:', error);
        }
    };

    const generateAdsComponents = () => {
        return data.map((ad, index) => (
            <>
                {ad.image ? (
                    <>
                        {/* <div key={index}> */}
                        <img src={`${imageurl}/${ad.image}`} width="100%" alt="Ads" className={`col-3 text-center m-1 ads${index + 1}_For_page2 adsImage`} />
                        {/* </div> */}
                    </>
                ) : (
                    <>
                        <div key={index} className={`col-3 text-center m-1 ads${index + 1}_For_page2 ${ad.Is_booked ? "bg-danger" : "notBooked"}`}>
                            {ad.image ? (
                                <></>
                            ) : (
                                <>
                                    <p>PageSize:- {ad.pageSize}</p>
                                    <p>unique_id:- {ad.unique_id}</p>
                                    <p>Price:- {ad.price}</p>
                                    {ad.client_id && <p>client_id:- {ad.client_id.name}</p>}
                                    {ad.Is_booked && <p><b>This ad is booked.</b></p>}
                                    {ad.Is_booked && <button className="allpage_book_btn" onClick={() => openModal(ad.booked_id)}>upload Img</button>}
                                    {!ad.Is_booked && <button className="allpage_book_btn" onClick={() => handleBookAds(ad._id)}>Book Ads</button>}
                                </>
                            )}
                        </div>
                    </>
                )}
            </>

        ));
    };
    return (
        <div className="container pt-5" style={{ paddingBottom: '8rem' }}>
            <h2 className="headings text-center mb-5">Selected Date:{formattedDateForPage}</h2>
            <div className="row d-flex justify-content-center ">
                <div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 col-12 page2">
                    <div className="row d-flex justify-content-center align-items-center p-2">
                        <div className="row d-flex justify-content-center align-items-center p-2" style={{ background: '#f8f9fb', borderRadius: '10px' }}>
                            <div className="col-4 bg-light pageDate"> <h5>{formattedDateForPage}</h5></div>
                            {cityId == 1 &&(
                            <div className="col-6 bg-light">  <img src={header} className="logo-for-page" /></div>

                            )}
                              {cityId == 2 &&(
                            <div className="col-6 bg-light">  <img src={gnvHeader} className="logo-for-page" /></div>

                            )}
                            <div className="col-2 bg-light pageScan" >  <img src={Scan} className="logo-for-page" /></div>
                        </div>
                        {generateAdsComponents()}
                    </div>
                    {/* </div> */}
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>add Your details:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="clientName">Name:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="clientName"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            {nameError && <p className="text-danger">{nameError}</p>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="clientEmail">Email:</label>
                            <input
                                type="email"
                                className="form-control"
                                id="clientEmail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            {emailError && <p className="text-danger">{emailError}</p>}

                        </div>
                        <div className="form-group">
                            <label htmlFor="clientLocation">Location:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="clientLocation"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                required
                            />
                            {locationError && <p className="text-danger">{locationError}</p>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="clientMobile">Mobile:</label>
                            <input
                                type="tel"
                                className="form-control"
                                id="clientMobile"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                onKeyDown={handleKeyDown}
                                required
                            />
                            {mobileError && <p className="text-danger">{mobileError}</p>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="clientMobile">GST No:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="clientgdtNo"
                                value={GST_No}
                                onChange={(e) => setGST_No(e.target.value)}
                            />
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" className="client-btn" onClick={handleSubmit}>
                        paynow
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={open_add_slotModal} onHide={closeModal} dialogClassName="modal-responsive">
                <Modal.Header closeButton>
                <Modal.Title>upload image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleUploadImage}>
                        <div className="form-group">
                            <label>upload file:</label>
                            <input
                                type="file"
                                className="form"
                                onChange={handleUploadChange}
                            />
                        </div>
                        {error && <div className="alert alert-danger">{error}</div>}
                        <button type="submit" className="btn btn-primary bg-primary" onClick={handleUploadImage}>Submit</button>
                    </form>
                </Modal.Body>
            </Modal>
        </div>

    );
}

export default Page2;

