import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import '../../../assets/css/FormComponent.css'; // Import your CSS file for styling
const baseurl = process.env.REACT_APP_BASE_URL;
const fetchProfile = process.env.REACT_APP_FETCH_PROFILE;
const profileImage = process.env.REACT_APP_PROFILE_IMAGE;
const updateProfile = process.env.REACT_APP_PROFILE_UPDATE
const fetchCategoriesData = process.env.REACT_APP_FETCH_CATEGORIES;
const FormComponent = () => {
    const [loading, setLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    let { id } = useParams();
    const navigate = useNavigate();
    const [displayProfileImage, setDisplayProfileImage] = useState(null);
    const [displayBannerImage, setDisplayBannerImage] = useState(null);
    const [displayAdsImage, setDisplayAdsImage] = useState(null);
    const [categoriesData, setCategoriesData] = useState([]);
    const [newCategory, setNewCategory] = useState('');

    const fetchCategories = async () => {
      try {
          const response = await axios.get(`${baseurl}/${fetchCategoriesData}`);
          let cData = [];
          if(response.data.data.length > 0){
            cData = response.data.data.map((item) => item._id);
            cData.push('Other');
          }else {
            cData.push('Other');
          }
          setCategoriesData(cData);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    };
    const [formData, setFormData] = useState({
        id: null,
        profilePhoto: null,
        name: '',
        designation: '',
        category: '',
        banner: null,
        adsPhoto: null,
        content: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        designation: '',
        category: '',
        content: ''
    });
    const handleBannerUpload = () => {
        if (formData.banner !== null) {
            let deleteBannerData = new FormData();
            deleteBannerData.append('deleteBannerImage', displayBannerImage);
            deleteBannerData.append('deleteFlag', 1);

            axios.post(`https://ahmedabadvisibilityventures.com/phpFileUploadScript/upload.php`,
                deleteBannerData,
                { headers: { 'Content-Type': 'multipart/form-data' } },
            ).then((response) => {
                let bannerImageData = new FormData();
                bannerImageData.append('bannerImage', formData.banner);
                bannerImageData.append('bannerFlag', 1);
                bannerImageData.append('addFlag', 1);

                axios.post(`https://ahmedabadvisibilityventures.com/phpFileUploadScript/upload.php`,
                    bannerImageData,
                    { headers: { 'Content-Type': 'multipart/form-data' } },
                ).then((responseData) => {
                    formData.banner = responseData.data.bannerImage.filename;

                    // After banner image upload is complete, proceed to update storeData
                    handleAdsUpload();
                }).catch(error => {
                    console.error('Error uploading banner image:', error.message);
                });
            }).catch(error => {
                console.error('Error deleting banner image:', error.message);
            });
        } else {
            formData.banner = displayBannerImage;
            handleAdsUpload(); // Call the function to update storeData directly
        }
    }

    const handleAdsUpload = () => {
        if (formData.adsPhoto !== null) {
            let deleteAdsData = new FormData();
            deleteAdsData.append('deleteAdsImage', displayAdsImage);
            deleteAdsData.append('deleteFlag', 1);

            axios.post(`https://ahmedabadvisibilityventures.com/phpFileUploadScript/upload.php`,
            deleteAdsData,
                { headers: { 'Content-Type': 'multipart/form-data' } },
            ).then((response) => {
                let adsImageData = new FormData();
                adsImageData.append('adsImage', formData.adsPhoto);
                adsImageData.append('adsFlag', 1);
                adsImageData.append('addFlag', 1);

                axios.post(`https://ahmedabadvisibilityventures.com/phpFileUploadScript/upload.php`,
                    adsImageData,
                    { headers: { 'Content-Type': 'multipart/form-data' } },
                ).then((responseData) => {
                    formData.adsPhoto = responseData.data.adsImage.filename;

                    // After banner image upload is complete, proceed to update storeData
                    updateStoreData();
                }).catch(error => {
                    console.error('Error uploading banner image:', error.message);
                });
            }).catch(error => {
                console.error('Error deleting banner image:', error.message);
            });
        } else {
            formData.adsPhoto = displayAdsImage;
            updateStoreData(); // Call the function to update storeData directly
        }
    }

    const updateStoreData = () => {
        // After both photo and banner uploads or their respective skips are complete
        console.log("========================");
        console.log(formData.profilePhoto);

        let storeData = {
            "id": formData.id,
            "name": formData.name.trim(),
            "designation": formData.designation.trim(),
            "category": formData.category.trim(),
            "content": formData.content,
            "profile_image": formData.profilePhoto,
            "banner_image": formData.banner,
            "ads_image": formData.adsPhoto
        };

        axios.post(`${baseurl}/${updateProfile}`,
            storeData,
            { headers: { 'Content-Type': 'application/json' } },
        ).then((responseData) => {
            setLoading(false);  // Stop loading animation
            setButtonDisabled(false);  // Enable button
            navigate("/admin/profileStory");
        }).catch(error => {
            if (error.response) {
                console.error('Server responded with:', error.response.data);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error setting up request:', error.message);
            }
        });
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        let valid = true;
        const newErrors = { ...errors };

        // Basic validation
        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
            valid = false;
        } else {
            newErrors.name = '';
        }


        if (!formData.designation.trim()) {
            newErrors.designation = 'Designation is required';
            valid = false;
        } else {
            newErrors.designation = '';
        }

        
        if (!formData.category.trim()) {
            newErrors.category = 'Category is required';
            valid = false;
        } else {
            newErrors.category = '';
            if(formData.category == 'Other') {
                if (!newCategory.trim()) {
                newErrors.newCategory = 'Category is required';
                valid = false;
                }else {
                newErrors.newCategory = '';
                formData.category = newCategory.trim();
                }
            }
        }

        if (!formData.content.trim()) {
            newErrors.content = 'Content is required';
            valid = false;
        } else {
            newErrors.content = '';
        }

        if (valid) {
            setButtonDisabled(true);  // Disable button
            setLoading(true);
            if (formData.profilePhoto !== null) {
                let deleteProfilePhotoData = new FormData();
                deleteProfilePhotoData.append('deleteProfilePhoto', displayProfileImage);
                deleteProfilePhotoData.append('deleteFlag', 1);

                axios.post(`https://ahmedabadvisibilityventures.com/phpFileUploadScript/upload.php`,
                    deleteProfilePhotoData,
                    { headers: { 'Content-Type': 'multipart/form-data' } },
                ).then((response) => {
                    let profileImageData = new FormData();
                    profileImageData.append('profilePhoto', formData.profilePhoto);
                    profileImageData.append('profileFlag', 1);
                    profileImageData.append('addFlag', 1);

                    axios.post(`https://ahmedabadvisibilityventures.com/phpFileUploadScript/upload.php`,
                        profileImageData,
                        { headers: { 'Content-Type': 'multipart/form-data' } },
                    ).then((responseData) => {
                        formData.profilePhoto = responseData.data.profilePhoto.filename;

                        // After profile photo upload is complete
                        handleBannerUpload(); // Call the function to handle banner upload
                    }).catch(error => {
                        console.error('Error uploading profile photo:', error.message);
                    });
                }).catch(error => {
                    console.error('Error deleting profile photo:', error.message);
                });
            } else {
                formData.profilePhoto = displayProfileImage;
                handleBannerUpload(); // Call the function to handle banner upload directly
            }

        } else {
            setErrors(newErrors);
        }
    };

    const handleFileChange = (e, fieldName) => {
        const file = e.target.files[0];
        const newErrors = { ...errors };

        // Check if a file is selected
        if (!file) {
            newErrors[fieldName] = `Please select a ${fieldName === 'profilePhoto' ? 'profile photo' : 'banner'} file.`;
            setErrors(newErrors);
            return;
        }

        // Validate file type (allow only images)
        if (!file.type.startsWith('image/')) {
            newErrors[fieldName] = `Please upload only image files for ${fieldName === 'profilePhoto' ? 'profile photo' : 'banner'}.`;
            setErrors(newErrors);
            return;
        }
        setFormData({ ...formData, [fieldName]: file });
        setErrors({ ...errors, [fieldName]: '' });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const fetchData = async () => {
        try {
            const response = await axios.post(`${baseurl}/${fetchProfile}`, { id: id });
            setFormData({
                ...formData,
                id: response.data.data._id,
                name: response.data.data.name,
                designation: response.data.data.designation,
                category: response.data.data.category,
                content: response.data.data.content
            });
            setDisplayProfileImage(response.data.data.profileImage);
            setDisplayBannerImage(response.data.data.bannerImage);
            setDisplayAdsImage(response.data.data.adsImage);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchCategories();
    }, []);

    return (
        <div className="form-container mt-40">
            <h1 className='mb-3 headings text-center text-black'>Edit Profile Detail</h1>
            <form method='post' onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Upload Profile Photo</label>
                    <img src={profileImage + 'profile_images/' + displayProfileImage} width="100" height="100" className='mb-2' />
                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'profilePhoto')} />
                    {errors.profilePhoto && <span className="error-message">{errors.profilePhoto}</span>}
                </div>
                <div className="form-group">
                    <label>Name</label>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} />
                    {errors.name && <span className="error-message">{errors.name}</span>}
                </div>
                <div className="form-group">
                    <label>Designation</label>
                    <input type="text" name="designation" value={formData.designation} onChange={handleChange} />
                    {errors.designation && <span className="error-message">{errors.designation}</span>}
                </div>
                <div className="form-group">
                    <label>Category</label>
                    <select
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        className="form-control"
                    >
                        <option value="">Select a category</option>
                        {categoriesData.map((cat) => (
                            <option key={cat} value={cat}>{cat}</option>
                        ))}
                    </select>
                    {errors.category && <span className="error-message">{errors.category}</span>}

                    { formData.category == 'Other' &&
                        <input
                            className="mt-2"
                            type="text"
                            placeholder="Enter new category"
                            value={newCategory}
                            onChange={(e) => setNewCategory(e.target.value)}
                            
                        />
                    }
                    {errors.newCategory && <span className="error-message">{errors.newCategory}</span>}
                </div>
                <div className="form-group">
                    <label>Upload Banner</label>
                    <img src={profileImage + 'banner_images/' + displayBannerImage} width="100" height="100" className='mb-2' />
                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'banner')} />
                    {errors.banner && <span className="error-message">{errors.banner}</span>}
                </div>
                <div className="form-group">
                    <label>Add Content</label>
                    <textarea name="content" value={formData.content} onChange={handleChange} rows="5" />
                    {errors.content && <span className="error-message">{errors.content}</span>}
                </div>
                <div className="form-group">
                    <label>Upload Ads</label>
                    <img src={profileImage + 'ads_images/' + displayAdsImage} width="100" height="100" className='mb-2' />
                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'adsPhoto')} />
                    {errors.adsPhoto && <span className="error-message">{errors.adsPhoto}</span>}
                </div>
                <div className="form-group">
                    <button type="submit" disabled={buttonDisabled}>{loading ? 'Loading...' : 'Update'}</button>
                </div>
            </form>
        </div>
    );
};

export default FormComponent;
