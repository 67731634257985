import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import FinalLogo from 'assets/images/logoImage.png';
import { FaQuestion } from "react-icons/fa";
import LastSection from 'assets/images/sidebarlast.png'
import routes from "routes.js";

const Sidebar = ({ open, onClose }) => {

  return (
    <div
      className={`sm:none h-100 sidenav duration-175  fixed !z-50 flex  flex-col shadow-2xl shadow-white/5 transition-all text-white  md:!z-50 lg:!z-50 xl:!z-0 ${open ? "translate-x-0" : "-translate-x-96"
        }`}
    >
      <span
        className="absolute top-2 right-2 block cursor-pointer closebtn xl:hidden"
        onClick={onClose} 
      >
        <HiX />
      </span>

      <div className="mb-5 mt-2 flex items-center justify-center" >

        <div className="mb-5  h-2.5 text-[26px] font-bold text-white">
          <img src={FinalLogo} style={{ width: "196px" }} className="logoimg" alt="Logo" />
        </div>
      </div>
      {/* <div className="mt-[41px] mb-1 h-px bg-white/30 hr-sidenav"  /> */}
      <ul className="mb-auto overflow-y-visible " >
        <Links routes={routes} />
      </ul>
      {/* Nav item end */}
      
      <div className="flex items-center justify-center" >

        <div className="font-bold text-white">
          <img src={LastSection} style={{ width: "196px" }} alt="last section" />

        </div>
      </div>

      {/* <div className="m-3 last-content-sidebar mb-5 text-center">
        <div className="last-sidebar  d-flex justify-content-center align-items-center">
          <div className="text-dark" >
            <FaQuestion className="question-icon" />
            <h5 className="text-dark mb-2"><b>Help Centre</b></h5>
            <p style={{ fontSize: '10px' }}>Having Trouble in Avv dashboard</p>
            <p style={{ fontSize: '10px' }}>Please contact Us for More Detail</p>
            <p className="mobile-btn mt-4 p-1">+91-9408170571</p>
          </div>
        </div>
      </div> */}

    </div>
  );
};

export default Sidebar;
