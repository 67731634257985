import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import axios from "axios";
import moment from 'moment';

const baseurl = process.env.REACT_APP_BASE_URL
const slotEndpoint = process.env.REACT_APP_SLOT_LIST_ENDPOINT;
const cityId = localStorage.getItem('cityId');

const Slot = () => {
    const navigate = useNavigate();
   
    const handlePageOpen = (day,slotId) => {
        navigate("/admin/Selectpage", { state: { day,slotId  } });
    };
    
    const [slots, setSlots] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${baseurl}/${slotEndpoint}`,{cityId:cityId});
                setSlots(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    const formatDate = (dateString) => {
        return moment(dateString).format('DD-MM-YYYY');
    }
    return (
        <>

            <div className="container table-container p-3 mt-5 mb-5">
            <div className="col-sm-12 col-lg-3 mb-3"><h3 className="table-Title p-2">Booked Ads</h3></div>
                <h1 className="headings text-center">Select date</h1>
                <div className="row d-flex justify-content-center mt-5">
                    {slots.map((slot, index) => (
                        slot.status ? (
                            <div
                                key={index}
                                className={`col-sm-12 col-md-3 col-lg-3 m-2 slotBox`}
                                onClick={() => handlePageOpen(slot.date,slot._id)}
                            >
                                {formatDate(slot.date)}
                            </div>
                        ) : null
                    ))}
                </div>
            </div>


        </>
    )
};

export default Slot;
