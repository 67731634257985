import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/admin";
import SignIn from 'layouts/auth'
import ProtectedRoutes from 'layouts/auth/protectedroute'

const App = () => {
  return (

    <BrowserRouter>
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path="admin/*" element={<AdminLayout />} />
          {/* <Route path="/" element={<Navigate to="/" replace />} /> */}
        </Route>
        <Route path="/" element={<SignIn />} />
      </Routes>
    </BrowserRouter>

  );
};

export default App;
