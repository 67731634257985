import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import '../../../assets/css/FormComponent.css'; // Import your CSS file for styling
const baseurl = process.env.REACT_APP_BASE_URL;
const addProfile = process.env.REACT_APP_ADD_PROFILE;
const fetchCategoriesData = process.env.REACT_APP_FETCH_CATEGORIES;
const FormComponent = () => {
    const [loading, setLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        profilePhoto: null,
        name: '',
        designation: '',
        category: '',
        banner: null,
        adsPhoto: null,
        content: ''
    });

  const [errors, setErrors] = useState({
    name: '',
    designation: '',
    category: '',
    content: ''
  });

    const [categoriesData, setCategoriesData] = useState([]);
    const [newCategory, setNewCategory] = useState('');

    useEffect(() => {
      fetchCategories();
    }, []);

    const fetchCategories = async () => {
      try {
          const response = await axios.get(`${baseurl}/${fetchCategoriesData}`);
          let cData = [];
          if(response.data.data.length > 0){
            cData = response.data.data.map((item) => item._id);
            cData.push('Other');
          }else {
            cData.push('Other');
          }
          setCategoriesData(cData);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    };

  const handleSubmit = async (e) => {
    
    e.preventDefault();
    let valid = true;
    const newErrors = { ...errors };

    // Basic validation
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
      valid = false;
    } else {
      newErrors.name = '';
    }

    if (!formData.profilePhoto) {
        newErrors.profilePhoto = 'Profile photo is required';
        valid = false;
      } else {
        newErrors.profilePhoto = '';
      }
  
      if (!formData.banner) {
        newErrors.banner = 'Banner is required';
        valid = false;
      } else {
        newErrors.banner = '';
      }

      if (!formData.adsPhoto) {
        newErrors.adsPhoto = 'Ads image is required';
        valid = false;
      } else {
        newErrors.adsPhoto = '';
      }

    if (!formData.designation.trim()) {
      newErrors.designation = 'Designation is required';
      valid = false;
    } else {
      newErrors.designation = '';
    }

    if (!formData.category.trim()) {
      newErrors.category = 'Category is required';
      valid = false;
    } else {
      newErrors.category = '';
      if(formData.category == 'Other') {
        if (!newCategory.trim()) {
          newErrors.newCategory = 'Category is required';
          valid = false;
        }else {
          newErrors.newCategory = '';
          formData.category = newCategory.trim();
        }
      }
    }

    if (!formData.content.trim()) {
      newErrors.content = 'Content is required';
      valid = false;
    } else {
      newErrors.content = '';
    }

    if (valid) {
        console.log(formData);
        setButtonDisabled(true);  // Disable button
        setLoading(true);
        const submitFormData = new FormData();
        submitFormData.append('profilePhoto', formData.profilePhoto);
        submitFormData.append('profileFlag', 1);
        submitFormData.append('bannerImage', formData.banner);
        submitFormData.append('bannerFlag', 1);
        submitFormData.append('adsImage', formData.adsPhoto);
        submitFormData.append('adsFlag', 1);
        submitFormData.append('addFlag', 1);
        axios.post(`https://ahmedabadvisibilityventures.com/phpFileUploadScript/upload.php`, 
        submitFormData,
        {headers: { 'Content-Type': 'multipart/form-data' }},
        ).then(response => {
            console.log('Form submitted:', response);
            let storeData = {
              "name" : formData.name.trim(),
              "designation" : formData.designation.trim(),
              "category" : formData.category.trim(),
              "content" : formData.content,
              "profile_image" : response.data.profilePhoto.filename,
              "banner_image" : response.data.bannerImage.filename,
              "ads_image" : response.data.adsImage.filename,
            }
            axios.post(`${baseurl}/${addProfile}`, 
            storeData,
            {headers: { 'Content-Type': 'application/json' }},
            ).then(response => {
              setLoading(false);  // Stop loading animation
              setButtonDisabled(false);  // Enable button
              navigate("/admin/profileStory");
            }).catch(error => {
              console.error('Error submitting form:', error);
              if (error.response) {
                  console.error('Server responded with:', error.response.data);
              } else if (error.request) {
                  console.error('No response received:', error.request);
              } else {
                  console.error('Error setting up request:', error.message);
              }
            });
        })
        .catch(error => {
            console.error('Error submitting form:', error);
            if (error.response) {
                console.error('Server responded with:', error.response.data);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error setting up request:', error.message);
            }
        });
    } else {
      setErrors(newErrors);
    }
  };

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    const newErrors = { ...errors };

    // Check if a file is selected
    if (!file) {
      newErrors[fieldName] = `Please select a ${fieldName === 'profilePhoto' ? 'profile photo' : 'banner'} file.`;
      setErrors(newErrors);
      return;
    }

    // Validate file type (allow only images)
    if (!file.type.startsWith('image/')) {
      newErrors[fieldName] = `Please upload only image files for ${fieldName === 'profilePhoto' ? 'profile photo' : 'banner'}.`;
      setErrors(newErrors);
      return;
    }
    setFormData({ ...formData, [fieldName]: file });
    setErrors({ ...errors, [fieldName]: '' });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

  return (
    <div className="form-container mt-40">
      <h1 className='mb-3 headings text-center text-black'>Fill Up Profile Detail</h1>
      <form method='post' onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Upload Profile Photo:</label>
          <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'profilePhoto')} />
          {errors.profilePhoto && <span className="error-message">{errors.profilePhoto}</span>}
        </div>
        <div className="form-group">
          <label>Name:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} />
          {errors.name && <span className="error-message">{errors.name}</span>}
        </div>
        <div className="form-group">
          <label>Designation:</label>
          <input type="text" name="designation" value={formData.designation} onChange={handleChange} />
          {errors.designation && <span className="error-message">{errors.designation}</span>}
        </div>
        <div className="form-group">
          <label>Category</label>
          <select
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="form-control"
          >
              <option value="">Select a category</option>
              {categoriesData.map((cat) => (
                  <option key={cat} value={cat}>{cat}</option>
              ))}
          </select>
          {errors.category && <span className="error-message">{errors.category}</span>}

          { formData.category == 'Other' &&
            <input
                className="mt-2"
                type="text"
                placeholder="Enter new category"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                
            />
          }
          {errors.newCategory && <span className="error-message">{errors.newCategory}</span>}
      </div>
        <div className="form-group">
          <label>Upload Banner:</label>
          <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'banner')} />
          {errors.banner && <span className="error-message">{errors.banner}</span>}
        </div>
        <div className="form-group">
          <label>Add Content:</label>
          <textarea name="content" value={formData.content} onChange={handleChange} />
          {errors.content && <span className="error-message">{errors.content}</span>}
        </div>
        <div className="form-group">
          <label>Upload Ads</label>
          <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'adsPhoto')} />
          {errors.adsPhoto && <span className="error-message">{errors.adsPhoto}</span>}
        </div>
        <div className="form-group">
          <button type="submit" disabled={buttonDisabled}>{loading ? 'Loading...' : 'Submit'} </button>
        </div>
      </form>
    </div>
  );
};

export default FormComponent;
