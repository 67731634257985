import React, { useEffect, useState } from "react";
import { Thead,Table,Tr,Th,Td, Tbody } from "react-super-responsive-table";
import axios from "axios";
import moment from 'moment';
// import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const baseurl = process.env.REACT_APP_BASE_URL
const slotEndpoint = process.env.REACT_APP_PAST_SLOT_LIST_ENDPOINT;
const bookedEndpoint = process.env.REACT_APP_FIND_BOOKED_ADS_ENDPOINT;
const cityId = localStorage.getItem('cityId');

const Slotlist = () => {
    const [slots, setSlots] = useState([]);
    const [totalBook ,setTotalBook] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseurl}/${slotEndpoint}`);
                setSlots(response.data.data);

                const totalBooked = {};
                for (const slot of response.data.data) {
                    const BookedAds_result = await axios.post(`${baseurl}/${bookedEndpoint}`, { slot_id: slot._id,cityId:cityId });
                   if(BookedAds_result.data.status == true){
                    totalBooked[slot._id] = BookedAds_result.data.data.length;
                   }else{
                    totalBooked[slot._id] = 0
                   }
                }
                setTotalBook(totalBooked);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const formatDate = (dateString) => {
        return moment(dateString).format('DD-MM-YYYY');
    };

    const handleView = (day, slotId) => {
        navigate("/admin/Selectpage", { state: { day, slotId } });
    };
    return (
        <>
            
                        <div className="container-fluid mt-5 table-container p-5">
                        <div className="col-sm-12 col-md-4 col-lg-3 mb-3"><h3 className="table-Title p-2">Past Slot</h3></div>
                            <Table>
                                <Thead className="table-head">
                                    <Tr>
                                        <Th>No.</Th>
                                        <Th>DATE</Th>
                                        <Th>TOTAL ADS BOOKED</Th>
                                        <Th>ACTION</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {slots.map((slot, index) => (
                                        <Tr key={index}>
                                            <Td>{index + 1}</Td>
                                            <Td>{formatDate(slot.date)}</Td>
                                            <Td>{totalBook[slot._id]}</Td>
                                            <Td>
                                            <button className="btn btn-info mx-2" onClick={() => handleView(slot.date,slot._id)}>view</button> 
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </div>

        </>
    )
}

export default Slotlist;
