import React, { useEffect, useState } from "react";
import axios from "axios";
import { Thead, Table, Tr, Th, Td, Tbody } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"; 

const baseurl = process.env.REACT_APP_BASE_URL;
const inquiryEndpoint = process.env.REACT_APP_INQUIRY_ENDPOINT;
const cityId = localStorage.getItem('cityId');
const Inquiry = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${baseurl}/${inquiryEndpoint}`;
                const response = await axios.post(url,{cityId:cityId});
                setData(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    return (
        <div className="container-fluid mt-5 table-container p-5">
            <div className="col-sm-12 col-md-3 col-lg-3  mb-3"><h3 className="table-Title p-2">Inquiry</h3></div>
            <Table className="responsiveTable">
                <Thead className="table-head">
                    <Tr>
                        <Th>No.</Th>
                        <Th>NAME</Th>
                        <Th>EMAIL</Th>
                        <Th>MOBILE</Th>
                        <Th>INQUIRY</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {data.map((item, index) => (
                        <Tr key={index}>
                            <Td>{index + 1}</Td>
                            <Td>{item.name}</Td>
                            <Td>{item.email}</Td>
                            <Td>{item.mobile}</Td>
                            <Td>{item.inquiry}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </div>
    );
};

export default Inquiry;
