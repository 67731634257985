import React, { useEffect, useState } from "react";
import { Thead, Table, Tr, Th, Td, Tbody } from "react-super-responsive-table";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import moment from 'moment';
import Swal from 'sweetalert2';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Editslot from './editSlot';
import Dropdown from 'react-bootstrap/Dropdown';


const create_Slot_Endpoint = process.env.REACT_APP_CREATE_SLOT_ENDPOINT;
const baseurl = process.env.REACT_APP_BASE_URL
const slotEndpoint = process.env.REACT_APP_SLOT_LIST_ENDPOINT;
const deleteSlotEndpoint = process.env.REACT_APP_SLOT_DELETE_ENDPOINT;
const cityId = localStorage.getItem('cityId');

const Slotlist = () => {
    const [slots, setSlots] = useState([]);
    const [date, setDate] = useState('');
    const [open_add_slotModal, setopen_add_slotModal] = useState(false);
    const [selectedSlotId, setSelectedSlotId] = useState(null);
    const [error, setError] = useState('');
    const [selectedCity, setSelectedCity] = useState(1);
    const navigate = useNavigate();

    const openModal = () => {
        setopen_add_slotModal(true);
    };

    const closeModal = () => {
        setopen_add_slotModal(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${baseurl}/${slotEndpoint}`, { cityId: cityId });
                setSlots(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
   

    const handleDelete = async (slotId) => {
        try {
            const response = await axios.delete(`${baseurl}/${deleteSlotEndpoint}`, { data: { _id: slotId } });
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Slot deleted successfully',
                });
            }
        } catch (error) {
            console.error('Error deleting slot:', error);
        }
    };

    const handleDateChange = (e) => {
        setDate(e.target.value);
        setError('');
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const formattedDate = moment(date).format('DD-MM-YYYY');
            const response = await axios.post(`${baseurl}/${create_Slot_Endpoint}`, { date: formattedDate,cityId: cityId  });
            if (response.data.statuscode == 201) {
                setError(response.data.message);
            }
            if (response.status === 200) {
                closeModal()
                window.location.reload()
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('DD-MM-YYYY');
    };

    const handleEdit = (slotId) => {
        setSelectedSlotId(slotId);
        navigate(`/admin/Editslot`, { state: { slotId } });
    };
    const handleView = (day, slotId) => {
        navigate("/admin/Selectpage", { state: { day, slotId } });
    };
    return (
        <>

            <div className="container-fluid mt-5 table-container p-5">
                <div className="row d-flex justify-content-between">
                    <button className="col-2 btn table-add-btn mb-2" onClick={openModal}>ADD +</button>
                </div>

                <Table>
                    <thead className="table-head">
                        <tr>
                            <th>No.</th>
                            <th>DATE</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {slots.map((slot, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{formatDate(slot.date)}</td>
                                <td>
                                    <button className="btn btn-primary" onClick={() => handleEdit(slot._id)}>edit</button>
                                    <button className="btn btn-danger mx-2" onClick={() => handleDelete(slot._id)}>delete</button>
                                    <button className="btn btn-info" onClick={() => handleView(slot.date, slot._id)}>view</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>


                <Modal show={open_add_slotModal} onHide={closeModal} dialogClassName="modal-responsive">
                    <Modal.Header closeButton>
                        <Modal.Title>Add Slot</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Date:</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={date}
                                    onChange={handleDateChange}
                                />
                            </div>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default Slotlist;
