import React, { useEffect, useState } from "react";
import { Thead, Table, Tr, Th, Td, Tbody } from "react-super-responsive-table";
import axios from "axios";
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
const baseurl = process.env.REACT_APP_BASE_URL;
const profileEndPoint = process.env.REACT_APP_PROFILE_LIST;
const profileDelete = process.env.REACT_APP_PROFILE_DELETE;
const profileImage = process.env.REACT_APP_PROFILE_IMAGE;
const updateStatus = process.env.REACT_APP_UPDATE_STATUS;
const Profilestory = () => {
    const navigate = useNavigate();
    const [open_add_slotModal, setopen_add_slotModal] = useState(false);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5); // Number of items per page
    const [deleteid, setDeleteid] = useState(0);
    const [deletename, setDeletename] = useState('');
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${baseurl}/${profileEndPoint}`);
            setData(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('DD-MM-YYYY');
    };

    const handleAddProfile = () => {
        navigate("/admin/AddProfile");
    };

    const handleEdit = (id) => {
        // Handle edit functionality
        navigate("/admin/EditProfile/"+id)
    };
    const handleHide = async (id, flag) => {
        const cindex = data.findIndex(item => item._id === id);
        console.log(cindex);
        axios.post(`${baseurl}/${updateStatus}`,
            {id: id, status: flag},
            { headers: { 'Content-Type': 'application/json' } },
        ).then((responseData) => {
            //currentItems[cindex].status = responseData.data.data.status 
            data[cindex].status = responseData.data.data.status;
            // Update the state with the modified data array
            setData([...data]);
        })
    }

    const handleDelete = async () => {
        let deletedItem = currentItems.filter((item) => item._id == deleteid);
        const submitFormData = new FormData();
        submitFormData.append('deleteProfilePhoto', deletedItem[0].profileImage);
        submitFormData.append('deleteBannerImage', deletedItem[0].bannerImage);
        submitFormData.append('deleteAdsImage', deletedItem[0].adsImage);
        submitFormData.append('deleteFlag', 1);
        axios.post(`https://ahmedabadvisibilityventures.com/phpFileUploadScript/upload.php`, 
        submitFormData,
        {headers: { 'Content-Type': 'multipart/form-data' }},
        ).then(response => {
            axios.post(`${baseurl}/${profileDelete}`, 
            {id: deletedItem[0]._id},
            {headers: { 'Content-Type': 'application/json' }},
            ).then(async (responseData) => {
              await fetchData();
              closeModal();
            }).catch(error => {
              if (error.response) {
                  console.error('Server responded with:', error.response.data);
              } else if (error.request) {
                  console.error('No response received:', error.request);
              } else {
                  console.error('Error setting up request:', error.message);
              }
            });
        });
    }

    // Logic to paginate data
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const openModal = async (id, name) => {
        setDeletename(name);
        setDeleteid(id);
        setopen_add_slotModal(true);
    };

    const closeModal = () => {
        setopen_add_slotModal(false);
    };
    return (
        <>
            <div className="container-fluid mt-40 table-container">
                <div className="row d-flex justify-content-between">
                    <button className="col-3 btn table-add-btn mb-2" onClick={handleAddProfile}>+ ADD PROFILE</button>
                </div>
                <Table>
                    <Thead className="table-head">
                        <Tr>
                            <Th>No.</Th>
                            <Th>Photo</Th>
                            <Th>Name</Th>
                            <Th>Designation</Th>
                            <Th>Category</Th>
                            <Th>Visitor Count</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {currentItems.map((item, index) => (
                            <Tr key={index}>
                                <Td>{index + 1}</Td>
                                <Td><img src={`${profileImage}profile_images/${item.profileImage}`} alt={item.profileImage} height="120" width="80"/></Td>
                                <Td>{item.name}</Td>
                                <Td>{item.designation}</Td>
                                <Td>{item.category}</Td>
                                <Td className="font-bold text-center text-black"><span className="bg-warning pl-2 pr-2 pt-1 pb-1">{item.visitorCount}</span></Td>
                                <Td>
                                    {(item.status === true)  && <button className="btn btn-info mr-3" onClick={() => handleHide(item._id, false)}>Hide</button> }
                                    {item.status === false  && <button className="btn btn-info mr-3" onClick={() => handleHide(item._id, true)}>Unhide</button> }
                                    <button className="btn btn-success" onClick={() => handleEdit(item._id)}>Edit</button>
                                    <span className="m-2"></span>
                                    <button className="btn btn-danger" onClick={() => openModal(item._id, item.name)}>Delete</button>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <Modal show={open_add_slotModal} onHide={closeModal} dialogClassName="modal-responsive">
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Profile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="mb-3">Are you sure you want delete {deletename} profile?</p>
                        <div className="float-right">
                            <button type="submit" className="btn btn-danger mr-2" onClick={handleDelete}>Delete</button>
                            <button type="submit" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* Pagination controls */}
                <nav aria-label="Pagination" className="pt-3">
                    <ul className="pagination justify-content-center">
                        {Array.from({ length: Math.ceil(data.length / itemsPerPage) }, (_, index) => (
                            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => paginate(index + 1)}>
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </>
    )
}

export default Profilestory;
