import Card from "components/card";

const Widget = ({ date}) => {
  

  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px] hover:text-white p-4  widgetDate" >
      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <h1>{date}</h1>
        {/* <h1 className="font-dm  font-medium text-dark-600">{title}</h1> */}
        {/* <h4 className="text-xl font-bold text-navy-700 hover:text-white subtitle">
          {subtitle}
        </h4> */}
      </div>
    </Card>
  );
};

export default Widget;
